.elementor-84 .elementor-element.elementor-element-2d11712f>.elementor-container {
  min-height: 500px;
}

.elementor-84 .elementor-element.elementor-element-2d11712f:not(.elementor-motion-effects-element-type-background),
.elementor-84 .elementor-element.elementor-element-2d11712f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #06070c;
  background-image: url("../../assets/images/snapedit_1714638980507.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.elementor-84 .elementor-element.elementor-element-2d11712f>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(90deg, #15162c 60%, #1e1e33 30%);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-2d11712f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 50px 0px;
}

.elementor-84 .elementor-element.elementor-element-696af89b {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-696af89b .elementor-heading-title {
  color: #e9c79e;
  font-family: "Arizonia", Sans-serif;
  font-size: 20em;
}

.elementor-bc-flex-widget .elementor-84 .elementor-element.elementor-element-72f45129.elementor-column .elementor-widget-wrap {
  align-items: center;
}

.elementor-84 .elementor-element.elementor-element-72f45129.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-84 .elementor-element.elementor-element-62a82ae8 {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-62a82ae8 .elementor-divider-separator {
  width: 10%;
  margin: 0 auto;
  margin-left: 0;
}

.elementor-84 .elementor-element.elementor-element-62a82ae8 .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}

.elementor-84 .elementor-element.elementor-element-23e9200e .elementor-heading-title {
  color: #e9c79e;
  font-family: "Montserrat", Sans-serif;
  font-size: 75px;
  letter-spacing: -3.5px;
}

.elementor-84 .elementor-element.elementor-element-65f4baa7 .elementor-heading-title {
  color: #ffffff;
  font-family: "Redressed", Sans-serif;
  font-size: 35px;
  font-style: italic;
}

.elementor-84 .elementor-element.elementor-element-6d6d8831 {
  --spacer-size: 150px;
}

.elementor-84 .elementor-element.elementor-element-7af38b6f {
  --divider-border-style: solid;
  --divider-color: rgba(255, 255, 255, 0.55);
  --divider-border-width: 1px;
}

.elementor-84 .elementor-element.elementor-element-7af38b6f .elementor-divider-separator {
  width: 50%;
  margin: 0 auto;
  margin-left: 0;
}

.elementor-84 .elementor-element.elementor-element-7af38b6f .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}

.elementor-84 .elementor-element.elementor-element-5f375df1>.elementor-container>.elementor-column>.elementor-widget-wrap {
  align-content: center;
  align-items: center;
}

.elementor-84 .elementor-element.elementor-element-5f375df1:not(.elementor-motion-effects-element-type-background),
.elementor-84 .elementor-element.elementor-element-5f375df1>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #f6f6f6;
}

.elementor-84 .elementor-element.elementor-element-5f375df1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0px 100px 0px;
}

.elementor-84 .elementor-element.elementor-element-5f375df1>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-76005791>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 0px;
}

.elementor-84 .elementor-element.elementor-element-76005791>.elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget .elementor-84 .elementor-element.elementor-element-1bc4270c.elementor-column .elementor-widget-wrap {
  align-items: center;
}

.elementor-84 .elementor-element.elementor-element-1bc4270c.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-84 .elementor-element.elementor-element-1bc4270c:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-1bc4270c>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: transparent;
  background-image: linear-gradient(90deg,
      #ffffff 50%,
      rgba(242, 41, 91, 0) 0%);
}

.elementor-84 .elementor-element.elementor-element-1bc4270c>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-1bc4270c>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-2d1f92f8 {
  padding: 35px 35px 35px 35px;
}

.elementor-84 .elementor-element.elementor-element-4bea4d14 .elementor-heading-title {
  color: #15162c;
  font-family: "Redressed", Sans-serif;
  font-size: 75px;
}

.elementor-84 .elementor-element.elementor-element-5080b0e4 .elementor-heading-title {
  color: #07090f;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
  letter-spacing: 1.5px;
}

.elementor-84 .elementor-element.elementor-element-1eb4d4e7 {
  color: #07090f;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
}

.elementor-84 .elementor-element.elementor-element-322967da {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 2px;
}

.elementor-84 .elementor-element.elementor-element-322967da .elementor-divider-separator {
  width: 15%;
  margin: 0 auto;
  margin-right: 0;
}

.elementor-84 .elementor-element.elementor-element-322967da .elementor-divider {
  text-align: right;
}

.elementor-84 .elementor-element.elementor-element-248e4c72 {
  margin-top: 50px;
  margin-bottom: 0px;
}

.elementor-84 .elementor-element.elementor-element-5ed72bb9:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-5ed72bb9>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../../assets/images/airport-at-sunrise.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-84 .elementor-element.elementor-element-5ed72bb9>.elementor-element-populated>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #13161d 71%, #1a2140 100%);
  opacity: 0.61;
}

.elementor-84 .elementor-element.elementor-element-5ed72bb9:hover>.elementor-element-populated>.elementor-background-overlay {
  background-color: #1a2140;
  opacity: 0.85;
}

.elementor-84 .elementor-element.elementor-element-5ed72bb9>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 120px 0px 120px 0px;
}

.elementor-84 .elementor-element.elementor-element-5ed72bb9>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-55049ecb {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-55049ecb .elementor-heading-title {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
}

.elementor-84 .elementor-element.elementor-element-55049ecb>.elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-20f441a8 {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-20f441a8 .elementor-divider-separator {
  width: 10%;
  margin: 0 auto;
  margin-center: 0;
}

.elementor-84 .elementor-element.elementor-element-20f441a8 .elementor-divider {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.elementor-84 .elementor-element.elementor-element-469855cf:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-469855cf>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../../assets/images/group-of-friends-having-fun-and-celebrating-group-gathering.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-84 .elementor-element.elementor-element-469855cf>.elementor-element-populated>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #13161d 71%, #1a2140 100%);
  opacity: 0.61;
}

.elementor-84 .elementor-element.elementor-element-469855cf:hover>.elementor-element-populated>.elementor-background-overlay {
  background-color: #1a2140;
  opacity: 0.85;
}

.elementor-84 .elementor-element.elementor-element-469855cf>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 120px 0px 120px 0px;
}

.elementor-84 .elementor-element.elementor-element-469855cf>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-574f3942 {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-574f3942 .elementor-heading-title {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
}

.elementor-84 .elementor-element.elementor-element-574f3942>.elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-14914c5b {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-14914c5b .elementor-divider-separator {
  width: 20%;
  margin: 0 auto;
  margin-center: 0;
}

.elementor-84 .elementor-element.elementor-element-14914c5b .elementor-divider {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.elementor-84 .elementor-element.elementor-element-1ca4855a:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-1ca4855a>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../../assets/images/booze-and-fun.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-84 .elementor-element.elementor-element-1ca4855a>.elementor-element-populated>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #13161d 71%, #1a2140 100%);
  opacity: 0.61;
}

.elementor-84 .elementor-element.elementor-element-1ca4855a:hover>.elementor-element-populated>.elementor-background-overlay {
  background-color: #1a2140;
  opacity: 0.85;
}

.elementor-84 .elementor-element.elementor-element-1ca4855a>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 120px 0px 120px 0px;
}

.elementor-84 .elementor-element.elementor-element-1ca4855a>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-73000457 {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-73000457 .elementor-heading-title {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
}

.elementor-84 .elementor-element.elementor-element-73000457>.elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-19ffcc46 {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-19ffcc46 .elementor-divider-separator {
  width: 20%;
  margin: 0 auto;
  margin-center: 0;
}

.elementor-84 .elementor-element.elementor-element-19ffcc46 .elementor-divider {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.elementor-84 .elementor-element.elementor-element-17f98714:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-17f98714>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../../assets/images/beautiful-wedding-couple.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-84 .elementor-element.elementor-element-17f98714>.elementor-element-populated>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #13161d 71%, #1a2140 100%);
  opacity: 0.61;
}

.elementor-84 .elementor-element.elementor-element-17f98714:hover>.elementor-element-populated>.elementor-background-overlay {
  background-color: #1a2140;
  opacity: 0.85;
}

.elementor-84 .elementor-element.elementor-element-17f98714>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 120px 0px 120px 0px;
}

.elementor-84 .elementor-element.elementor-element-17f98714>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-5db20573 {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-5db20573 .elementor-heading-title {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
}

.elementor-84 .elementor-element.elementor-element-5db20573>.elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-1384646e {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-1384646e .elementor-divider-separator {
  width: 10%;
  margin: 0 auto;
  margin-center: 0;
}

.elementor-84 .elementor-element.elementor-element-1384646e .elementor-divider {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.elementor-84 .elementor-element.elementor-element-ef7d5ca {
  margin-top: 0px;
  margin-bottom: 50px;
}

.elementor-84 .elementor-element.elementor-element-7e665686:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-7e665686>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../../assets/images/businesswoman-doing-business-paperwork-on-car-back-seat.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-84 .elementor-element.elementor-element-7e665686>.elementor-element-populated>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #13161d 71%, #1a2140 100%);
  opacity: 0.61;
}

.elementor-84 .elementor-element.elementor-element-7e665686:hover>.elementor-element-populated>.elementor-background-overlay {
  background-color: #1a2140;
  opacity: 0.85;
}

.elementor-84 .elementor-element.elementor-element-7e665686>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 120px 0px 120px 0px;
}

.elementor-84 .elementor-element.elementor-element-7e665686>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-485505bc {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-485505bc .elementor-heading-title {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
}

.elementor-84 .elementor-element.elementor-element-485505bc>.elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-6e72cdcb {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-6e72cdcb .elementor-divider-separator {
  width: 10%;
  margin: 0 auto;
  margin-center: 0;
}

.elementor-84 .elementor-element.elementor-element-6e72cdcb .elementor-divider {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.elementor-84 .elementor-element.elementor-element-41ae7014:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-84 .elementor-element.elementor-element-41ae7014>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../../assets/images/car-on-a-road-in-a-national-park.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-84 .elementor-element.elementor-element-41ae7014>.elementor-element-populated>.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #13161d 71%, #1a2140 100%);
  opacity: 0.61;
}

.elementor-84 .elementor-element.elementor-element-41ae7014:hover>.elementor-element-populated>.elementor-background-overlay {
  background-color: #1a2140;
  opacity: 0.85;
}

.elementor-84 .elementor-element.elementor-element-41ae7014>.elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 120px 0px 120px 0px;
}

.elementor-84 .elementor-element.elementor-element-41ae7014>.elementor-element-populated>.elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-84 .elementor-element.elementor-element-1e3fc351 {
  text-align: center;
}

.elementor-84 .elementor-element.elementor-element-1e3fc351 .elementor-heading-title {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
}

.elementor-84 .elementor-element.elementor-element-1e3fc351>.elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-84 .elementor-element.elementor-element-ca7ac7 {
  --divider-border-style: solid;
  --divider-color: #dcb57e;
  --divider-border-width: 3px;
}

.elementor-84 .elementor-element.elementor-element-ca7ac7 .elementor-divider-separator {
  width: 20%;
  margin: 0 auto;
  margin-center: 0;
}

.elementor-84 .elementor-element.elementor-element-ca7ac7 .elementor-divider {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

@media (max-width: 1024px) {

  .elementor-84 .elementor-element.elementor-element-2d11712f:not(.elementor-motion-effects-element-type-background),
  .elementor-84 .elementor-element.elementor-element-2d11712f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-repeat: no-repeat;
    background-size: 150% auto;
  }

  .elementor-84 .elementor-element.elementor-element-2d11712f {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .elementor-84 .elementor-element.elementor-element-696af89b .elementor-heading-title {
    font-size: 15em;
  }

  .elementor-84 .elementor-element.elementor-element-6d6d8831 {
    --spacer-size: 80px;
  }

  .elementor-84 .elementor-element.elementor-element-5f375df1 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-84 .elementor-element.elementor-element-2d1f92f8 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {

  .elementor-84 .elementor-element.elementor-element-2d11712f:not(.elementor-motion-effects-element-type-background),
  .elementor-84 .elementor-element.elementor-element-2d11712f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-repeat: no-repeat;
    background-size: 740px auto;
  }

  .elementor-84 .elementor-element.elementor-element-2d11712f {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .elementor-84 .elementor-element.elementor-element-696af89b .elementor-heading-title {
    font-size: 100px;
  }

  .elementor-84 .elementor-element.elementor-element-62a82ae8 .elementor-divider {
    text-align: center;
  }

  .elementor-84 .elementor-element.elementor-element-62a82ae8 .elementor-divider-separator {
    margin: 0 auto;
    margin-center: 0;
  }

  .elementor-84 .elementor-element.elementor-element-23e9200e {
    text-align: center;
  }

  .elementor-84 .elementor-element.elementor-element-65f4baa7 {
    text-align: center;
  }

  .elementor-84 .elementor-element.elementor-element-65f4baa7 .elementor-heading-title {
    font-size: 30px;
  }

  .elementor-84 .elementor-element.elementor-element-6d6d8831 {
    --spacer-size: 60px;
  }

  .elementor-84 .elementor-element.elementor-element-7af38b6f .elementor-divider {
    text-align: center;
  }

  .elementor-84 .elementor-element.elementor-element-7af38b6f .elementor-divider-separator {
    margin: 0 auto;
    margin-center: 0;
  }

  .elementor-84 .elementor-element.elementor-element-4bea4d14 .elementor-heading-title {
    font-size: 40px;
    line-height: 1em;
  }

  .elementor-84 .elementor-element.elementor-element-5080b0e4 .elementor-heading-title {
    font-size: 20px;
    line-height: 1em;
  }
}

@media (min-width: 768px) {
  .elementor-84 .elementor-element.elementor-element-1f093990 {
    width: 40%;
  }

  .elementor-84 .elementor-element.elementor-element-72f45129 {
    width: 60%;
  }

  .elementor-84 .elementor-element.elementor-element-76005791 {
    width: 40%;
  }

  .elementor-84 .elementor-element.elementor-element-1bc4270c {
    width: 60%;
  }

  .elementor-84 .elementor-element.elementor-element-5ed72bb9 {
    width: 65%;
  }

  .elementor-84 .elementor-element.elementor-element-469855cf {
    width: 35%;
  }

  .elementor-84 .elementor-element.elementor-element-1ca4855a {
    width: 35%;
  }

  .elementor-84 .elementor-element.elementor-element-17f98714 {
    width: 65%;
  }

  .elementor-84 .elementor-element.elementor-element-7e665686 {
    width: 65%;
  }

  .elementor-84 .elementor-element.elementor-element-41ae7014 {
    width: 35%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-84 .elementor-element.elementor-element-76005791 {
    width: 100%;
  }

  .elementor-84 .elementor-element.elementor-element-1bc4270c {
    width: 100%;
  }
}

.services-container {
  width: 100%;
  padding-inline: 10%;
  display: flex;
  margin-block: 50px;
  flex-wrap: wrap;
  justify-content: center;
}

.service-container {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 45%;
  margin: 10px;
  position: relative;
}

.service-container .service-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.5);
}

.service-container:hover .service-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.8);
}

.service-title {
  position: absolute;
  font-size: 150%;
  top: 50%;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 700px) {
  .service-title {
    font-size: 120%;
    font-weight: 300;
  }
}

@media screen and (max-width: 500px) {
  .service-title {
    position: absolute;
    font-size: 100%;
  }

  .service-container {
    width: 100%;
  }

  .services-container {
    padding-inline: 5%;
  }

  .service-title {
    font-size: 130%;
  }
}

@media screen and (max-width: 400px) {
  .service-title {
    position: absolute;
    font-size: 150%;
  }
}