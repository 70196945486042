.elementor-66 .elementor-element.elementor-element-6169c44e>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-66 .elementor-element.elementor-element-6169c44e:not(.elementor-motion-effects-element-type-background),
.elementor-66 .elementor-element.elementor-element-6169c44e>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #15162C;
}

.elementor-66 .elementor-element.elementor-element-6169c44e {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 5px 0px 5px 0px;
}

.elementor-66 .elementor-element.elementor-element-6169c44e>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-66 .elementor-element.elementor-element-44886ef3>.elementor-element-populated {
    padding: 20px 0px 20px 0px;
}

.elementor-66 .elementor-element.elementor-element-6a880b4d {
    text-align: left;
}

.elementor-66 .elementor-element.elementor-element-6a880b4d img {
    width: 80%;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-menu-toggle {
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu .elementor-item {
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.5em;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main .elementor-item {
    color: #ffffff;
    fill: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main .elementor-item:hover,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main .elementor-item.elementor-item-active,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main .elementor-item.highlighted,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main .elementor-item:focus {
    color: #FFFFFF;
    fill: #FFFFFF;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main:not(.e--pointer-framed) .elementor-item:before,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main:not(.e--pointer-framed) .elementor-item:after {
    background-color: #dcb57e;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed .elementor-item:before,
.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed .elementor-item:after {
    border-color: #dcb57e;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main .elementor-item.elementor-item-active {
    color: #FFFFFF;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main:not(.e--pointer-framed) .elementor-item.elementor-item-active:before,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main:not(.e--pointer-framed) .elementor-item.elementor-item-active:after {
    background-color: #dcb57e;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed .elementor-item.elementor-item-active:before,
.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed .elementor-item.elementor-item-active:after {
    border-color: #dcb57e;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed .elementor-item:before {
    border-width: 2px;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed.e--animation-draw .elementor-item:before {
    border-width: 0 0 2px 2px;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed.e--animation-draw .elementor-item:after {
    border-width: 2px 2px 0 0;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed.e--animation-corners .elementor-item:before {
    border-width: 2px 0 0 2px;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-framed.e--animation-corners .elementor-item:after {
    border-width: 0 2px 2px 0;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-underline .elementor-item:after,
.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-overline .elementor-item:before,
.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-double-line .elementor-item:before,
.elementor-66 .elementor-element.elementor-element-5906e24 .e--pointer-double-line .elementor-item:after {
    height: 2px;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-menu-toggle {
    color: #ffffff;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown {
    background-color: #2B2E56;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a:hover,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a.elementor-item-active,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a.highlighted,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-menu-toggle:hover {
    color: #ffffff;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a:hover,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a.elementor-item-active,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a.highlighted {
    background-color: #1a1c32;
}

.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown .elementor-item,
.elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown .elementor-sub-item {
    font-family: "Montserrat", Sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}

.elementor-66 .elementor-element.elementor-element-5906e24 div.elementor-menu-toggle {
    color: #ffffff;
}

.elementor-66 .elementor-element.elementor-element-5906e24 div.elementor-menu-toggle svg {
    fill: #ffffff;
}

.elementor-66 .elementor-element.elementor-element-5906e24 {
    --nav-menu-icon-size: 40px;
}

.elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button {
    font-family: "Redressed", Sans-serif;
    font-size: 20px;
    fill: #E9C79E;
    color: #E9C79E;
    background-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #E9C79E;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 10px 30px -9px rgba(0, 0, 0, 0.16);
    padding: 12px 30px 12px 30px;
}

.elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button:hover,
.elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button:focus {
    color: #15162C;
    background-color: #e9c79e;
    border-color: #e8ad66;
}

.elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button:hover svg,
.elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button:focus svg {
    fill: #15162C;
}

@media(max-width:1024px) {
    .elementor-66 .elementor-element.elementor-element-6169c44e {
        padding: 20px 20px 20px 20px;
    }

    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown .elementor-item,
    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown .elementor-sub-item {
        font-size: 13px;
    }

    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--dropdown a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main>.elementor-nav-menu>li>.elementor-nav-menu--dropdown,
    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu__container.elementor-nav-menu--dropdown {
        margin-top: 45px !important;
    }

    .elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button {
        font-size: 20px;
        border-width: 1px 1px 1px 1px;
    }
}

@media(max-width:767px) {
    .elementor-66 .elementor-element.elementor-element-6169c44e {
        padding: 5px 5px 5px 05px;
    }

    .elementor-66 .elementor-element.elementor-element-44886ef3 {
        width: 100%;
    }

    .elementor-66 .elementor-element.elementor-element-6a880b4d {
        text-align: center;
    }

    .elementor-66 .elementor-element.elementor-element-6a880b4d img {
        width: 90%;
    }

    .elementor-66 .elementor-element.elementor-element-439e3acc {
        width: 25%;
    }

    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu--main>.elementor-nav-menu>li>.elementor-nav-menu--dropdown,
    .elementor-66 .elementor-element.elementor-element-5906e24 .elementor-nav-menu__container.elementor-nav-menu--dropdown {
        margin-top: 14px !important;
    }

    .elementor-66 .elementor-element.elementor-element-6aa06b5b {
        width: 75%;
    }

    .elementor-66 .elementor-element.elementor-element-4def12ab .elementor-button {
        font-size: 18px;
    }
}

@media(min-width:768px) {
    .elementor-66 .elementor-element.elementor-element-44886ef3 {
        width: 25%;
    }

    .elementor-66 .elementor-element.elementor-element-439e3acc {
        width: 49.996%;
    }

    .elementor-66 .elementor-element.elementor-element-6aa06b5b {
        width: 25%;
    }


}

@media (max-width: 1024px) {
    .menu__container-open {
        top: 60px;
        --menu-height: 100vmax;
        width: 98vw;
        left: calc(-50vw);
    }
}

@media (max-width: 767px) {
    .menu__container-open {
        top: 60px;
        --menu-height: 1000vmax;
        width: 97.5vw;
        left: -15px;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-66 .elementor-element.elementor-element-44886ef3 {
        width: 50%;
    }

    .elementor-66 .elementor-element.elementor-element-439e3acc {
        width: 10%;
    }

    .elementor-66 .elementor-element.elementor-element-6aa06b5b {
        width: 40%;
    }
}