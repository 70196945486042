.elementor-74 .elementor-element.elementor-element-38fe6bd7>.elementor-container {
    min-height: 500px;
}

.elementor-74 .elementor-element.elementor-element-38fe6bd7:not(.elementor-motion-effects-element-type-background),
.elementor-74 .elementor-element.elementor-element-38fe6bd7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #06070C;
    background-image: url("../../assets/images/snapedit_1714638980507.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.elementor-74 .elementor-element.elementor-element-38fe6bd7>.elementor-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(90deg, #15162C 60%, #1e1e33 30%);
    opacity: 0.8;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-74 .elementor-element.elementor-element-38fe6bd7 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
}

.elementor-74 .elementor-element.elementor-element-2c94b38a {
    text-align: center;
}

.elementor-74 .elementor-element.elementor-element-2c94b38a .elementor-heading-title {
    color: #E9C79E;
    font-family: "Arizonia", Sans-serif;
    font-size: 20em;
}

.elementor-bc-flex-widget .elementor-74 .elementor-element.elementor-element-40848877.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-74 .elementor-element.elementor-element-40848877.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-74 .elementor-element.elementor-element-b86299e {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-74 .elementor-element.elementor-element-b86299e .elementor-divider-separator {
    width: 10%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-74 .elementor-element.elementor-element-b86299e .elementor-divider {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-74 .elementor-element.elementor-element-644a9b67 .elementor-heading-title {
    color: #E9C79E;
    font-family: "Montserrat", Sans-serif;
    font-size: 75px;
    letter-spacing: -3.5px;
}

.elementor-74 .elementor-element.elementor-element-18b2d97b .elementor-heading-title {
    color: #ffffff;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    font-style: italic;
}

.elementor-74 .elementor-element.elementor-element-111db30e {
    --spacer-size: 150px;
}

.elementor-74 .elementor-element.elementor-element-635bd9fa {
    --divider-border-style: solid;
    --divider-color: rgba(255, 255, 255, 0.55);
    --divider-border-width: 1px;
}

.elementor-74 .elementor-element.elementor-element-635bd9fa .elementor-divider-separator {
    width: 50%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-74 .elementor-element.elementor-element-635bd9fa .elementor-divider {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-74 .elementor-element.elementor-element-3452ec09>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-74 .elementor-element.elementor-element-3452ec09:not(.elementor-motion-effects-element-type-background),
.elementor-74 .elementor-element.elementor-element-3452ec09>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #0d0e1e;
}

.elementor-74 .elementor-element.elementor-element-3452ec09 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-74 .elementor-element.elementor-element-3452ec09>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-74 .elementor-element.elementor-element-3a4c8d95>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-74 .elementor-element.elementor-element-3a4c8d95>.elementor-element-populated {
    padding: 0px 20px 0px 20px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group {
    padding-right: calc(10px/2);
    padding-left: calc(10px/2);
    margin-bottom: 30px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-form-fields-wrapper {
    margin-left: calc(-10px/2);
    margin-right: calc(-10px/2);
    margin-bottom: -30px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group.recaptcha_v3-bottomleft,
.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group.recaptcha_v3-bottomright {
    margin-bottom: 0;
}

body.rtl .elementor-74 .elementor-element.elementor-element-2f46794e .elementor-labels-inline .elementor-field-group>label {
    padding-left: 0px;
}

body:not(.rtl) .elementor-74 .elementor-element.elementor-element-2f46794e .elementor-labels-inline .elementor-field-group>label {
    padding-right: 0px;
}

body .elementor-74 .elementor-element.elementor-element-2f46794e .elementor-labels-above .elementor-field-group>label {
    padding-bottom: 0px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group>label,
.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-subgroup label {
    color: #FFFFFF;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-type-html {
    padding-bottom: 0px;
    font-family: "Montserrat", Sans-serif;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group .elementor-field {
    color: #000000;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group .elementor-field,
.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-subgroup label {
    font-family: "Montserrat", Sans-serif;
    font-size: 13px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group:not(.elementor-field-type-upload) .elementor-field:not(.elementor-select-wrapper) {
    background-color: #F5F5F4;
    border-color: #dcb57e;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group .elementor-select-wrapper select {
    background-color: #F5F5F4;
    border-color: #dcb57e;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-field-group .elementor-select-wrapper::before {
    color: #dcb57e;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-button {
    font-family: "Redressed", Sans-serif;
    font-size: 25px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .e-form__buttons__wrapper__button-next {
    background-color: #0B0B17;
    color: #ffffff;
    border-color: #D5B07C;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-button[type="submit"] {
    background-color: #0B0B17;
    color: #ffffff;
    border-color: #D5B07C;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-button[type="submit"] svg * {
    fill: #ffffff;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .e-form__buttons__wrapper__button-previous {
    color: #ffffff;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .e-form__buttons__wrapper__button-next:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-button[type="submit"]:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .elementor-button[type="submit"]:hover svg * {
    fill: #ffffff;
}

.elementor-74 .elementor-element.elementor-element-2f46794e .e-form__buttons__wrapper__button-previous:hover {
    color: #ffffff;
}

.elementor-74 .elementor-element.elementor-element-2f46794e {
    --e-form-steps-indicators-spacing: 20px;
    --e-form-steps-indicator-padding: 30px;
    --e-form-steps-indicator-inactive-secondary-color: #ffffff;
    --e-form-steps-indicator-active-secondary-color: #ffffff;
    --e-form-steps-indicator-completed-secondary-color: #ffffff;
    --e-form-steps-divider-width: 1px;
    --e-form-steps-divider-gap: 10px;
}

.elementor-74 .elementor-element.elementor-element-2f46794e>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 30px 40px 30px 40px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0px 0px 10px 0px rgba(219, 212, 201, 0.88);
}

.elementor-bc-flex-widget .elementor-74 .elementor-element.elementor-element-9fe4511.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-74 .elementor-element.elementor-element-9fe4511.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-74 .elementor-element.elementor-element-9fe4511:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-74 .elementor-element.elementor-element-9fe4511>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(90deg, #1a1c32 50%, rgba(242, 41, 91, 0) 0%);
}

.elementor-74 .elementor-element.elementor-element-9fe4511>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-74 .elementor-element.elementor-element-9fe4511>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-74 .elementor-element.elementor-element-3963cbef {
    padding: 35px 35px 35px 35px;
}

.elementor-74 .elementor-element.elementor-element-17bc2257 .elementor-heading-title {
    color: #ffffff;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    letter-spacing: 1.5px;
}

.elementor-74 .elementor-element.elementor-element-58ca6354 .elementor-heading-title {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    letter-spacing: 1.5px;
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px/2);
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px/2);
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: calc(10px/2);
    margin-left: calc(10px/2);
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-10px/2);
    margin-left: calc(-10px/2);
}

body.rtl .elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    left: calc(-10px/2);
}

body:not(.rtl) .elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    right: calc(-10px/2);
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-icon i {
    color: #dcb57e;
    transition: color 0.3s;
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-icon svg {
    fill: #dcb57e;
    transition: fill 0.3s;
}

.elementor-74 .elementor-element.elementor-element-27539988 {
    --e-icon-list-icon-size: 17px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25) 0 0;
    --icon-vertical-offset: 0px;
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-icon {
    padding-right: 15px;
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-item>.elementor-icon-list-text,
.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-item>a {
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-style: italic;
}

.elementor-74 .elementor-element.elementor-element-27539988 .elementor-icon-list-text {
    color: #ffffff;
    transition: color 0.3s;
}

.elementor-74 .elementor-element.elementor-element-27539988>.elementor-widget-container {
    padding: 20px 20px 20px 20px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #dcb57e;
}

.elementor-74 .elementor-element.elementor-element-30c1b121 {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-74 .elementor-element.elementor-element-29909eee {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 2px;
}

.elementor-74 .elementor-element.elementor-element-29909eee .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-right: 0;
}

.elementor-74 .elementor-element.elementor-element-29909eee .elementor-divider {
    text-align: right;
    padding-top: 15px;
    padding-bottom: 15px;
}

@media(max-width:1024px) {

    .elementor-74 .elementor-element.elementor-element-38fe6bd7:not(.elementor-motion-effects-element-type-background),
    .elementor-74 .elementor-element.elementor-element-38fe6bd7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-repeat: no-repeat;
        background-size: 150% auto;
    }

    .elementor-74 .elementor-element.elementor-element-38fe6bd7 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-74 .elementor-element.elementor-element-2c94b38a .elementor-heading-title {
        font-size: 15em;
    }

    .elementor-74 .elementor-element.elementor-element-111db30e {
        --spacer-size: 80px;
    }

    .elementor-74 .elementor-element.elementor-element-3452ec09 {
        padding: 50px 0px 50px 0px;
    }

    .elementor-74 .elementor-element.elementor-element-3a4c8d95>.elementor-element-populated {
        margin: 0px 0px 50px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }
}

@media(max-width:767px) {

    .elementor-74 .elementor-element.elementor-element-38fe6bd7:not(.elementor-motion-effects-element-type-background),
    .elementor-74 .elementor-element.elementor-element-38fe6bd7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-repeat: no-repeat;
        background-size: 740px auto;
    }

    .elementor-74 .elementor-element.elementor-element-38fe6bd7 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-74 .elementor-element.elementor-element-2c94b38a .elementor-heading-title {
        font-size: 100px;
    }

    .elementor-74 .elementor-element.elementor-element-b86299e .elementor-divider {
        text-align: center;
    }

    .elementor-74 .elementor-element.elementor-element-b86299e .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-74 .elementor-element.elementor-element-644a9b67 {
        text-align: center;
    }

    .elementor-74 .elementor-element.elementor-element-18b2d97b {
        text-align: center;
    }

    .elementor-74 .elementor-element.elementor-element-18b2d97b .elementor-heading-title {
        font-size: 30px;
    }

    .elementor-74 .elementor-element.elementor-element-111db30e {
        --spacer-size: 60px;
    }

    .elementor-74 .elementor-element.elementor-element-635bd9fa .elementor-divider {
        text-align: center;
    }

    .elementor-74 .elementor-element.elementor-element-635bd9fa .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-74 .elementor-element.elementor-element-3452ec09 {
        padding: 0px 0px 0px 0px;
    }

    .elementor-74 .elementor-element.elementor-element-2f46794e>.elementor-widget-container {
        margin: 20px 20px 20px 20px;
    }

    .elementor-74 .elementor-element.elementor-element-17bc2257 .elementor-heading-title {
        font-size: 40px;
        line-height: 1em;
    }

    .elementor-74 .elementor-element.elementor-element-58ca6354 .elementor-heading-title {
        font-size: 20px;
        line-height: 1em;
    }
}

@media(min-width:768px) {
    .elementor-74 .elementor-element.elementor-element-217b523d {
        width: 40%;
    }

    .elementor-74 .elementor-element.elementor-element-40848877 {
        width: 60%;
    }

    .elementor-74 .elementor-element.elementor-element-3a4c8d95 {
        width: 45%;
    }

    .elementor-74 .elementor-element.elementor-element-9fe4511 {
        width: 55%;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-74 .elementor-element.elementor-element-3a4c8d95 {
        width: 100%;
    }

    .elementor-74 .elementor-element.elementor-element-9fe4511 {
        width: 100%;
    }
}