.elementor-62 .elementor-element.elementor-element-2499bbe4:not(.elementor-motion-effects-element-type-background),
.elementor-62 .elementor-element.elementor-element-2499bbe4>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #06070C;
    background-position: center center;
    background-size: cover;
}

.elementor-62 .elementor-element.elementor-element-2499bbe4>.elementor-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(90deg, #15162C 60%, #1e1e33 30%);
    opacity: 0.8;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-62 .elementor-element.elementor-element-2499bbe4 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
}

.elementor-bc-flex-widget .elementor-62 .elementor-element.elementor-element-6558c2db.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-62 .elementor-element.elementor-element-6558c2db.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-62 .elementor-element.elementor-element-5f560d42 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-62 .elementor-element.elementor-element-5f560d42 .elementor-divider-separator {
    width: 10%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-62 .elementor-element.elementor-element-5f560d42 .elementor-divider {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-62 .elementor-element.elementor-element-5a99d381 .elementor-heading-title {
    color: #E9C79E;
    font-family: "Montserrat", Sans-serif;
    font-size: 50px;
    line-height: 1.5em;
    letter-spacing: -3.5px;
}

.elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-icon i {
    color: #DCB57E;
    font-size: 14px;
}

.elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-icon svg {
    fill: #DCB57E;
    --e-icon-list-icon-size: 14px;
}

.elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-icon {
    width: 14px;
}

.elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-text,
.elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-text a {
    color: #FFFFFF;
}

.elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-item {
    font-family: "Redressed", Sans-serif;
    font-size: 15px;
    text-transform: capitalize;
}

.elementor-62 .elementor-element.elementor-element-7f10abac {
    --divider-border-style: solid;
    --divider-color: rgba(255, 255, 255, 0.55);
    --divider-border-width: 1px;
}

.elementor-62 .elementor-element.elementor-element-7f10abac .elementor-divider-separator {
    width: 50%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-62 .elementor-element.elementor-element-7f10abac .elementor-divider {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-62 .elementor-element.elementor-element-356c7a7c {
    --grid-side-margin: 10px;
    --grid-column-gap: 10px;
    --grid-row-gap: 10px;
    --grid-bottom-margin: 10px;
}

.elementor-62 .elementor-element.elementor-element-356c7a7c .elementor-share-btn__title {
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-62 .elementor-element.elementor-element-387a61b9>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-62 .elementor-element.elementor-element-387a61b9:not(.elementor-motion-effects-element-type-background),
.elementor-62 .elementor-element.elementor-element-387a61b9>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #f6f6f6;
}

.elementor-62 .elementor-element.elementor-element-387a61b9 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-62 .elementor-element.elementor-element-387a61b9>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget .elementor-62 .elementor-element.elementor-element-6600cc0b.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-62 .elementor-element.elementor-element-6600cc0b.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-62 .elementor-element.elementor-element-6600cc0b>.elementor-element-populated {
    padding: 0px 40px 0px 20px;
}

.elementor-62 .elementor-element.elementor-element-7f8c6a6f {
    text-align: justify;
    color: #000000;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-62 .elementor-element.elementor-element-8b02400 {
    --spacer-size: 10px;
}

.elementor-62 .elementor-element.elementor-element-29526846 span.post-navigation__prev--label {
    color: #D1B38E;
}

.elementor-62 .elementor-element.elementor-element-29526846 span.post-navigation__next--label {
    color: #D1B38E;
}

.elementor-62 .elementor-element.elementor-element-29526846 span.post-navigation__prev--label,
.elementor-62 .elementor-element.elementor-element-29526846 span.post-navigation__next--label {
    font-size: 10px;
}

.elementor-62 .elementor-element.elementor-element-29526846 span.post-navigation__prev--title,
.elementor-62 .elementor-element.elementor-element-29526846 span.post-navigation__next--title {
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
}

.elementor-62 .elementor-element.elementor-element-29526846 .post-navigation__arrow-wrapper {
    color: #DCB57E;
}

.elementor-62 .elementor-element.elementor-element-6324ebe4>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-62 .elementor-element.elementor-element-6324ebe4>.elementor-element-populated {
    padding: 0px 10px 0px 30px;
}

.elementor-62 .elementor-element.elementor-element-57b726b6 {
    text-align: left;
}

.elementor-62 .elementor-element.elementor-element-57b726b6 .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
}

.elementor-62 .elementor-element.elementor-element-57b726b6>.elementor-widget-container {
    margin: 0px 20px 0px 0px;
    padding: 0px 20px 0px 0px;
}

.elementor-62 .elementor-element.elementor-element-402c6ff6 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-62 .elementor-element.elementor-element-402c6ff6 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-62 .elementor-element.elementor-element-402c6ff6 .elementor-divider {
    text-align: left;
}

.elementor-62 .elementor-element.elementor-element-402c6ff6>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group {
    padding-right: calc(10px/2);
    padding-left: calc(10px/2);
    margin-bottom: 22px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-form-fields-wrapper {
    margin-left: calc(-10px/2);
    margin-right: calc(-10px/2);
    margin-bottom: -22px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group.recaptcha_v3-bottomleft,
.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group.recaptcha_v3-bottomright {
    margin-bottom: 0;
}

body.rtl .elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-labels-inline .elementor-field-group>label {
    padding-left: 0px;
}

body:not(.rtl) .elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-labels-inline .elementor-field-group>label {
    padding-right: 0px;
}

body .elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-labels-above .elementor-field-group>label {
    padding-bottom: 0px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group>label,
.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-subgroup label {
    color: #54595f;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-type-html {
    padding-bottom: 0px;
    font-family: "Montserrat", Sans-serif;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group .elementor-field {
    color: #000000;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group .elementor-field,
.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-subgroup label {
    font-family: "Montserrat", Sans-serif;
    font-size: 13px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group:not(.elementor-field-type-upload) .elementor-field:not(.elementor-select-wrapper) {
    background-color: #ffffff;
    border-color: #dcb57e;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group .elementor-select-wrapper select {
    background-color: #ffffff;
    border-color: #dcb57e;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-field-group .elementor-select-wrapper::before {
    color: #dcb57e;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-button {
    font-family: "Redressed", Sans-serif;
    font-size: 25px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .e-form__buttons__wrapper__button-next {
    background-color: #0b0b17;
    color: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-button[type="submit"] {
    background-color: #0b0b17;
    color: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-button[type="submit"] svg * {
    fill: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .e-form__buttons__wrapper__button-previous {
    color: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .e-form__buttons__wrapper__button-next:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-button[type="submit"]:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .elementor-button[type="submit"]:hover svg * {
    fill: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 .e-form__buttons__wrapper__button-previous:hover {
    color: #ffffff;
}

.elementor-62 .elementor-element.elementor-element-53a4a799 {
    --e-form-steps-indicators-spacing: 20px;
    --e-form-steps-indicator-padding: 30px;
    --e-form-steps-indicator-inactive-secondary-color: #ffffff;
    --e-form-steps-indicator-active-secondary-color: #ffffff;
    --e-form-steps-indicator-completed-secondary-color: #ffffff;
    --e-form-steps-divider-width: 1px;
    --e-form-steps-divider-gap: 10px;
}

.elementor-62 .elementor-element.elementor-element-53a4a799>.elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 40px 20px 40px 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(219, 212, 201, 0.88);
}

.elementor-62 .elementor-element.elementor-element-74851692 {
    --spacer-size: 10px;
}

.elementor-62 .elementor-element.elementor-element-4d33743 {
    --spacer-size: 30px;
}

.elementor-62 .elementor-element.elementor-element-7d41be09 {
    text-align: left;
}

.elementor-62 .elementor-element.elementor-element-7d41be09 .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    font-weight: bold;
    line-height: 1em;
}

.elementor-62 .elementor-element.elementor-element-7d41be09>.elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 20px 0px 0px;
}

.elementor-62 .elementor-element.elementor-element-203116ef {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-62 .elementor-element.elementor-element-203116ef .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-62 .elementor-element.elementor-element-203116ef .elementor-divider {
    text-align: left;
}

.elementor-62 .elementor-element.elementor-element-203116ef>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
}

.elementor-62 .elementor-element.elementor-element-42339ab2 {
    --grid-column-gap: 30px;
    --grid-row-gap: 15px;
}

.elementor-62 .elementor-element.elementor-element-42339ab2 .elementor-post__title,
.elementor-62 .elementor-element.elementor-element-42339ab2 .elementor-post__title a {
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-style: italic;
    text-decoration: underline;
}

@media(min-width:768px) {
    .elementor-62 .elementor-element.elementor-element-6600cc0b {
        width: 70%;
    }

    .elementor-62 .elementor-element.elementor-element-6324ebe4 {
        width: 30%;
    }
}

@media(max-width:1024px) {

    .elementor-62 .elementor-element.elementor-element-2499bbe4:not(.elementor-motion-effects-element-type-background),
    .elementor-62 .elementor-element.elementor-element-2499bbe4>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-repeat: no-repeat;
        background-size: 150% auto;
    }

    .elementor-62 .elementor-element.elementor-element-2499bbe4 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-62 .elementor-element.elementor-element-356c7a7c {
        --grid-side-margin: 10px;
        --grid-column-gap: 10px;
        --grid-row-gap: 10px;
        --grid-bottom-margin: 10px;
    }

    .elementor-62 .elementor-element.elementor-element-6600cc0b>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }

    .elementor-62 .elementor-element.elementor-element-6324ebe4>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }
}

@media(max-width:767px) {

    .elementor-62 .elementor-element.elementor-element-2499bbe4:not(.elementor-motion-effects-element-type-background),
    .elementor-62 .elementor-element.elementor-element-2499bbe4>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-repeat: no-repeat;
        background-size: 740px auto;
    }

    .elementor-62 .elementor-element.elementor-element-2499bbe4 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-62 .elementor-element.elementor-element-5f560d42 .elementor-divider {
        text-align: center;
    }

    .elementor-62 .elementor-element.elementor-element-5f560d42 .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-62 .elementor-element.elementor-element-5a99d381 {
        text-align: center;
    }

    .elementor-62 .elementor-element.elementor-element-5a99d381 .elementor-heading-title {
        font-size: 25px;
        line-height: 1.5em;
        letter-spacing: -1px;
    }

    .elementor-62 .elementor-element.elementor-element-5a99d381>.elementor-widget-container {
        margin: 10px 10px 10px 10px;
        padding: 10px 10px 10px 10px;
    }

    .elementor-62 .elementor-element.elementor-element-25fdde0f .elementor-icon-list-item {
        font-size: 15px;
    }

    .elementor-62 .elementor-element.elementor-element-7f10abac .elementor-divider {
        text-align: center;
    }

    .elementor-62 .elementor-element.elementor-element-7f10abac .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-62 .elementor-element.elementor-element-356c7a7c {
        --grid-side-margin: 10px;
        --grid-column-gap: 10px;
        --grid-row-gap: 10px;
        --grid-bottom-margin: 10px;
    }

    .elementor-62 .elementor-element.elementor-element-356c7a7c .elementor-share-btn__title {
        font-size: 10px;
    }

    .elementor-62 .elementor-element.elementor-element-6600cc0b>.elementor-element-populated {
        padding: 15px 15px 15px 15px;
    }

    .elementor-62 .elementor-element.elementor-element-6324ebe4>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-62 .elementor-element.elementor-element-7d41be09 .elementor-heading-title {
        font-size: 30px;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-62 .elementor-element.elementor-element-30783def {
        width: 90%;
    }

    .elementor-62 .elementor-element.elementor-element-45df0e9d {
        width: 10%;
    }

    .elementor-62 .elementor-element.elementor-element-6600cc0b {
        width: 100%;
    }

    .elementor-62 .elementor-element.elementor-element-6324ebe4 {
        width: 100%;
    }
}

.elementor-inline-items .elementor-icon-list-item {
    margin-right: 15px;
}