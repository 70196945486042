.elementor-90 .elementor-element.elementor-element-4ba62e4b>.elementor-container {
    min-height: 700px;
}

.elementor-90 .elementor-element.elementor-element-4ba62e4b:not(.elementor-motion-effects-element-type-background),
.elementor-90 .elementor-element.elementor-element-4ba62e4b>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #161938;
    background-image: url("../../assets/images/snapedit_1714638980507.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
}

.elementor-90 .elementor-element.elementor-element-4ba62e4b>.elementor-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #15162cb5 45%, #0f1223af 81%);
    opacity: 0.9;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-90 .elementor-element.elementor-element-4ba62e4b {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
}

.elementor-90 .elementor-element.elementor-element-bf6f21e>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-90 .elementor-element.elementor-element-3f9b6008 {
    --spacer-size: 30px;
}

.elementor-90 .elementor-element.elementor-element-28f6e201 {
    text-align: left;
}

.elementor-90 .elementor-element.elementor-element-28f6e201 .elementor-heading-title {
    color: #e9c79e;
    font-family: "Arizonia", Sans-serif;
    font-size: 165px;
    font-weight: 100;
}

.elementor-90 .elementor-element.elementor-element-51ab3fd6 {
    --spacer-size: 50px;
}

.elementor-90 .elementor-element.elementor-element-33d0eccc {
    text-align: right;
}

.elementor-90 .elementor-element.elementor-element-33d0eccc .elementor-heading-title {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 65px;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: -2.5px;
}

.elementor-90 .elementor-element.elementor-element-4f22e3fe {
    text-align: right;
}

.elementor-90 .elementor-element.elementor-element-4f22e3fe .elementor-heading-title {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 40px;
    font-weight: 100;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: -1.5px;
}

.elementor-90 .elementor-element.elementor-element-2c5b92bb {
    --spacer-size: 100px;
    width: var(--container-widget-width, 1px);
    max-width: 1px;
    --container-widget-width: 1px;
    --container-widget-flex-grow: 0;
    top: 125%;
}

body:not(.rtl) .elementor-90 .elementor-element.elementor-element-2c5b92bb {
    left: 50%;
}

body.rtl .elementor-90 .elementor-element.elementor-element-2c5b92bb {
    right: 50%;
}

.elementor-90 .elementor-element.elementor-element-2c5b92bb>.elementor-widget-container {
    background-color: #E9C79E;
}

.elementor-90 .elementor-element.elementor-element-233b2348:not(.elementor-motion-effects-element-type-background),
.elementor-90 .elementor-element.elementor-element-233b2348>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(90deg, #15162c 50%, #0a0a13 100%);
}

.elementor-90 .elementor-element.elementor-element-233b2348 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-90 .elementor-element.elementor-element-233b2348>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-90 .elementor-element.elementor-element-390a92d7>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget .elementor-90 .elementor-element.elementor-element-3d1c0d17.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-90 .elementor-element.elementor-element-3d1c0d17.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-90 .elementor-element.elementor-element-3d1c0d17:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-90 .elementor-element.elementor-element-3d1c0d17>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(90deg, #1a1c32 50%, rgba(242, 41, 91, 0) 0%);
}

.elementor-90 .elementor-element.elementor-element-3d1c0d17>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-90 .elementor-element.elementor-element-3d1c0d17>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-90 .elementor-element.elementor-element-43232ca4 {
    padding: 35px 35px 35px 35px;
}

.elementor-90 .elementor-element.elementor-element-e0d5dd1 .elementor-heading-title {
    color: #dcb57e;
    font-family: "Redressed", Sans-serif;
    font-size: 75px;
    font-weight: 500;
    letter-spacing: 1.5px;
}

.elementor-90 .elementor-element.elementor-element-7d273e3 {
    text-align: justify;
    color: #FFFFFF;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-90 .elementor-element.elementor-element-7b1c7f59 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 2px;
}

.elementor-90 .elementor-element.elementor-element-7b1c7f59 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-right: 0;
}

.elementor-90 .elementor-element.elementor-element-7b1c7f59 .elementor-divider {
    text-align: right;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-90 .elementor-element.elementor-element-51fafdef>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-90 .elementor-element.elementor-element-51fafdef:not(.elementor-motion-effects-element-type-background),
.elementor-90 .elementor-element.elementor-element-51fafdef>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #f6f6f6;
}

.elementor-90 .elementor-element.elementor-element-51fafdef {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-90 .elementor-element.elementor-element-51fafdef>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget .elementor-90 .elementor-element.elementor-element-3048e1b8.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-90 .elementor-element.elementor-element-3048e1b8.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-90 .elementor-element.elementor-element-3048e1b8>.elementor-element-populated {
    padding: 20px 40px 0px 0px;
}



.elementor-90 .elementor-element.elementor-element-15e84f9 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-90 .elementor-element.elementor-element-15e84f9 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-90 .elementor-element.elementor-element-15e84f9 .elementor-divider {
    text-align: left;
}

.elementor-90 .elementor-element.elementor-element-3caac843 {
    text-align: justify;
    color: #000000;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-90 .elementor-element.elementor-element-60d57cb9 {
    --spacer-size: 10px;
}

.elementor-90 .elementor-element.elementor-element-37d95ada>.elementor-element-populated {
    padding: 20px 10px 0px 10px;
}

.elementor-90 .elementor-element.elementor-element-566f642a {
    text-align: left;
}

.elementor-90 .elementor-element.elementor-element-566f642a .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    line-height: 1em;
}

.elementor-90 .elementor-element.elementor-element-566f642a>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 0px 20px 0px 0px;
}

.elementor-90 .elementor-element.elementor-element-3a5c7ec3 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-90 .elementor-element.elementor-element-3a5c7ec3 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-90 .elementor-element.elementor-element-3a5c7ec3 .elementor-divider {
    text-align: left;
}

.elementor-90 .elementor-element.elementor-element-3a5c7ec3>.elementor-widget-container {
    padding: 0px 0px 0px 20px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group {
    padding-right: calc(10px/2);
    padding-left: calc(10px/2);
    margin-bottom: 22px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-form-fields-wrapper {
    margin-left: calc(-10px/2);
    margin-right: calc(-10px/2);
    margin-bottom: -22px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group.recaptcha_v3-bottomleft,
.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group.recaptcha_v3-bottomright {
    margin-bottom: 0;
}

body.rtl .elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-labels-inline .elementor-field-group>label {
    padding-left: 0px;
}

body:not(.rtl) .elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-labels-inline .elementor-field-group>label {
    padding-right: 0px;
}

body .elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-labels-above .elementor-field-group>label {
    padding-bottom: 0px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group>label,
.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-subgroup label {
    color: #54595f;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-type-html {
    padding-bottom: 0px;
    font-family: "Montserrat", Sans-serif;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group .elementor-field {
    color: #000000;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group .elementor-field,
.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-subgroup label {
    font-family: "Montserrat", Sans-serif;
    font-size: 13px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group:not(.elementor-field-type-upload) .elementor-field:not(.elementor-select-wrapper) {
    background-color: #ffffff;
    border-color: #dcb57e;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group .elementor-select-wrapper select {
    background-color: #ffffff;
    border-color: #dcb57e;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-field-group .elementor-select-wrapper::before {
    color: #dcb57e;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-button {
    font-family: "Redressed", Sans-serif;
    font-size: 25px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .e-form__buttons__wrapper__button-next {
    background-color: #0b0b17;
    color: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-button[type="submit"] {
    background-color: #0b0b17;
    color: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-button[type="submit"] svg * {
    fill: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .e-form__buttons__wrapper__button-previous {
    color: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .e-form__buttons__wrapper__button-next:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-button[type="submit"]:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .elementor-button[type="submit"]:hover svg * {
    fill: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af .e-form__buttons__wrapper__button-previous:hover {
    color: #ffffff;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af {
    --e-form-steps-indicators-spacing: 20px;
    --e-form-steps-indicator-padding: 30px;
    --e-form-steps-indicator-inactive-secondary-color: #ffffff;
    --e-form-steps-indicator-active-secondary-color: #ffffff;
    --e-form-steps-indicator-completed-secondary-color: #ffffff;
    --e-form-steps-divider-width: 1px;
    --e-form-steps-divider-gap: 10px;
}

.elementor-90 .elementor-element.elementor-element-6eb9e7af>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 30px 40px 30px 40px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(219, 212, 201, 0.88);
}

.elementor-90 .elementor-element.elementor-element-432aa851:not(.elementor-motion-effects-element-type-background),
.elementor-90 .elementor-element.elementor-element-432aa851>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(90deg, #15162c 50%, #0a0a13 100%);
}

.elementor-90 .elementor-element.elementor-element-432aa851 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-90 .elementor-element.elementor-element-432aa851>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget .elementor-90 .elementor-element.elementor-element-5888d092.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-90 .elementor-element.elementor-element-5888d092.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-90 .elementor-element.elementor-element-5888d092:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-90 .elementor-element.elementor-element-5888d092>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(90deg, #1a1c32 50%, rgba(242, 41, 91, 0) 0%);
}

.elementor-90 .elementor-element.elementor-element-5888d092>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-90 .elementor-element.elementor-element-5888d092>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-90 .elementor-element.elementor-element-76432491>.elementor-container {
    max-width: 1120px;
}

.elementor-90 .elementor-element.elementor-element-76432491 {
    padding: 35px 35px 35px 35px;
}

.elementor-90 .elementor-element.elementor-element-1f117e4f>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-90 .elementor-element.elementor-element-2b23b52d .elementor-heading-title {
    color: #dcb57e;
    font-family: "Redressed", Sans-serif;
    font-size: 75px;
    letter-spacing: 1.5px;
}

.elementor-90 .elementor-element.elementor-element-988ca92 .elementor-heading-title {
    color: #FFFFFF;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    font-weight: 200;
    letter-spacing: 0.5px;
}

.elementor-90 .elementor-element.elementor-element-ad87503>.elementor-container {
    max-width: 1120px;
}

.elementor-90 .elementor-element.elementor-element-ad87503 {
    padding: 35px 35px 35px 35px;
}

.elementor-90 .elementor-element.elementor-element-79764363.elementor-view-stacked .elementor-icon {
    background-color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-79764363.elementor-view-framed .elementor-icon,
.elementor-90 .elementor-element.elementor-element-79764363.elementor-view-default .elementor-icon {
    fill: #DCB57E;
    color: #DCB57E;
    border-color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-79764363 {
    --icon-box-icon-margin: 15px;
}

.elementor-90 .elementor-element.elementor-element-79764363 .elementor-icon {
    font-size: 75px;
}

.elementor-90 .elementor-element.elementor-element-79764363 .elementor-icon-box-title {
    color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-79764363 .elementor-icon-box-title,
.elementor-90 .elementor-element.elementor-element-79764363 .elementor-icon-box-title a {
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    font-weight: normal;
    text-transform: capitalize;
}

.elementor-90 .elementor-element.elementor-element-79764363 .elementor-icon-box-description {
    color: #FFFFFF;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-90 .elementor-element.elementor-element-494e7e13.elementor-view-stacked .elementor-icon {
    background-color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-494e7e13.elementor-view-framed .elementor-icon,
.elementor-90 .elementor-element.elementor-element-494e7e13.elementor-view-default .elementor-icon {
    fill: #DCB57E;
    color: #DCB57E;
    border-color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-494e7e13 {
    --icon-box-icon-margin: 15px;
}

.elementor-90 .elementor-element.elementor-element-494e7e13 .elementor-icon {
    font-size: 75px;
}

.elementor-90 .elementor-element.elementor-element-494e7e13 .elementor-icon-box-title {
    color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-494e7e13 .elementor-icon-box-title,
.elementor-90 .elementor-element.elementor-element-494e7e13 .elementor-icon-box-title a {
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    text-transform: capitalize;
}

.elementor-90 .elementor-element.elementor-element-494e7e13 .elementor-icon-box-description {
    color: #FFFFFF;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8.elementor-view-stacked .elementor-icon {
    background-color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8.elementor-view-framed .elementor-icon,
.elementor-90 .elementor-element.elementor-element-1e2e6fc8.elementor-view-default .elementor-icon {
    fill: #DCB57E;
    color: #DCB57E;
    border-color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8 {
    --icon-box-icon-margin: 15px;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8 .elementor-icon {
    font-size: 75px;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8 .elementor-icon-box-title {
    color: #DCB57E;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8 .elementor-icon-box-title,
.elementor-90 .elementor-element.elementor-element-1e2e6fc8 .elementor-icon-box-title a {
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    text-transform: capitalize;
}

.elementor-90 .elementor-element.elementor-element-1e2e6fc8 .elementor-icon-box-description {
    color: #FFFFFF;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-90 .elementor-element.elementor-element-4dcba748 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 2px;
}

.elementor-90 .elementor-element.elementor-element-4dcba748 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-right: 0;
}

.elementor-90 .elementor-element.elementor-element-4dcba748 .elementor-divider {
    text-align: right;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-90 .elementor-element.elementor-element-5c31668>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-90 .elementor-element.elementor-element-5c31668 {
    padding: 100px 0px 100px 0px;
}

.elementor-90 .elementor-element.elementor-element-22f37dd4 .elementor-heading-title {
    color: #0a0a14;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    letter-spacing: 1.5px;
}

.elementor-90 .elementor-element.elementor-element-1a4f3e78 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-90 .elementor-element.elementor-element-1a4f3e78 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-90 .elementor-element.elementor-element-1a4f3e78 .elementor-divider {
    text-align: left;
}

.elementor-90 .elementor-element.elementor-element-9e9ac57 {
    color: #000000;
    font-family: "Montserrat", Sans-serif;
}

.elementor-90 .elementor-element .elementor-button {
    font-family: "Redressed", Sans-serif;
    font-size: 25px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #15162C;
    padding: 20px 50px 20px 50px;
}

@media(max-width:1024px) {
    .elementor-90 .elementor-element.elementor-element-4ba62e4b {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-90 .elementor-element.elementor-element-28f6e201 {
        text-align: center;
    }

    .elementor-90 .elementor-element.elementor-element-33d0eccc {
        text-align: center;
    }

    .elementor-90 .elementor-element.elementor-element-33d0eccc .elementor-heading-title {
        font-size: 50px;
    }

    .elementor-90 .elementor-element.elementor-element-33d0eccc>.elementor-widget-container {
        padding: 20px 20px 20px 20px;
    }

    .elementor-90 .elementor-element.elementor-element-4f22e3fe {
        text-align: center;
    }

    .elementor-90 .elementor-element.elementor-element-4f22e3fe .elementor-heading-title {
        font-size: 50px;
    }

    .elementor-90 .elementor-element.elementor-element-4f22e3fe>.elementor-widget-container {
        padding: 20px 20px 20px 20px;
    }

    .elementor-90 .elementor-element.elementor-element-2c5b92bb {
        top: 105%;
    }

    .elementor-90 .elementor-element.elementor-element-3048e1b8>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }

    .elementor-90 .elementor-element.elementor-element-37d95ada>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }

    .elementor-90 .elementor-element.elementor-element-76432491 {
        padding: 25px 25px 25px 25px;
    }

    .elementor-90 .elementor-element.elementor-element-ad87503 {
        padding: 25px 25px 25px 25px;
    }

    .elementor-90 .elementor-element.elementor-element-5c31668 {
        padding: 30px 30px 30px 30px;
    }

    .elementor-90 .elementor-element.elementor-element-1baa6855 .elementor-button {
        font-size: 10px;
        line-height: 1.5em;
        padding: 10px 30px 10px 30px;
    }
}

@media(max-width:767px) {
    .elementor-90 .elementor-element.elementor-element-4ba62e4b>.elementor-container {
        min-height: 40px;
    }

    .elementor-90 .elementor-element.elementor-element-4ba62e4b {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-90 .elementor-element.elementor-element-28f6e201 .elementor-heading-title {
        font-size: 85px;
    }

    .elementor-90 .elementor-element.elementor-element-33d0eccc {
        text-align: center;
    }

    .elementor-90 .elementor-element.elementor-element-33d0eccc .elementor-heading-title {
        font-size: 25px;
        letter-spacing: -2px;
    }

    .elementor-90 .elementor-element.elementor-element-4f22e3fe {
        text-align: center;
    }

    .elementor-90 .elementor-element.elementor-element-4f22e3fe .elementor-heading-title {
        font-size: 25px;
        letter-spacing: -2px;
    }

    .elementor-90 .elementor-element.elementor-element-2c5b92bb {
        --spacer-size: 55px;
        top: 95%;
    }

    .elementor-90 .elementor-element.elementor-element-e0d5dd1 .elementor-heading-title {
        font-size: 45px;
        line-height: 1em;
    }

    .elementor-90 .elementor-element.elementor-element-3048e1b8>.elementor-element-populated {
        padding: 15px 15px 15px 15px;
    }



    .elementor-90 .elementor-element.elementor-element-37d95ada>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-90 .elementor-element.elementor-element-566f642a .elementor-heading-title {
        font-size: 30px;
    }

    .elementor-90 .elementor-element.elementor-element-2b23b52d .elementor-heading-title {
        font-size: 45px;
        line-height: 1em;
    }

    .elementor-90 .elementor-element.elementor-element-988ca92 .elementor-heading-title {
        font-size: 30px;
    }

    .elementor-90 .elementor-element.elementor-element-5c31668 {
        padding: 10px 10px 10px 10px;
    }

    .elementor-90 .elementor-element.elementor-element-1baa6855 .elementor-button {
        font-size: 10px;
        letter-spacing: 4.5px;
    }
}

@media(min-width:768px) {
    .elementor-90 .elementor-element.elementor-element-390a92d7 {
        width: 45%;
    }

    .elementor-90 .elementor-element.elementor-element-3d1c0d17 {
        width: 55%;
    }

    .elementor-90 .elementor-element.elementor-element-3048e1b8 {
        width: 55%;
    }

    .elementor-90 .elementor-element.elementor-element-37d95ada {
        width: 45%;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-90 .elementor-element.elementor-element-390a92d7 {
        width: 100%;
    }

    .elementor-90 .elementor-element.elementor-element-3d1c0d17 {
        width: 100%;
    }

    .elementor-90 .elementor-element.elementor-element-3048e1b8 {
        width: 100%;
    }

    .elementor-90 .elementor-element.elementor-element-37d95ada {
        width: 100%;
    }

    .elementor-90 .elementor-element.elementor-element-5888d092 {
        width: 100%;
    }
}

.elementor-90 .elementor-element.elementor-element-5109082b {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
}

.elementor-90 .elementor-element.elementor-element-2eabe8f8 {
    text-align: center;
}

.elementor-90 .elementor-element.elementor-element-2eabe8f8 .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
}

.elementor-90 .elementor-element.elementor-element-2eabe8f8>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 0px 20px 0px 0px;
}

.elementor-90 .elementor-element.elementor-element-1c8de2e8 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-90 .elementor-element.elementor-element-1c8de2e8 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-center: 0;
}

.elementor-90 .elementor-element.elementor-element-1c8de2e8 .elementor-divider {
    text-align: center;
}

.elementor-90 .elementor-element.elementor-element-1c8de2e8>.elementor-widget-container {
    padding: 0px 0px 0px 20px;
}

.elementor-90 .elementor-element .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif !important;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-image-box-div {
    position: relative;
    height: max-content !important;
}

.elementor-90 .elementor-element .elementor-button-book {
    font-family: "Redressed", Sans-serif;
    width: auto !important;
    font-size: 25px;
    fill: #DCB57E;
    color: #DCB57E;
    border: 2px solid #DCB57E;
    background-color: #15162c27;
    padding: 10px 30px 10px 30px;
}

.elementor-90 .elementor-element .elementor-button-book:hover {
    fill: #FFFFFF;
    color: #FFFFFF;
    border: 2px solid #DCB57E;
    background-color: #DCB57E;
}

.elementor-image-box-link {
    /* position: absolute; */
    width: 100%;
    bottom: 0.5em;
}