.elementor-74 .elementor-element.elementor-element-5109082b {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
}

.elementor-74 .elementor-element.elementor-element-2eabe8f8 {
    text-align: center;
}

.elementor-74 .elementor-element.elementor-element-2eabe8f8 .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
}

.elementor-74 .elementor-element.elementor-element-2eabe8f8>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 0px 20px 0px 0px;
}

.elementor-74 .elementor-element.elementor-element-1c8de2e8 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-74 .elementor-element.elementor-element-15e84f9 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-74 .elementor-element.elementor-element-15e84f9 .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-74 .elementor-element.elementor-element-1c8de2e8 .elementor-divider {
    text-align: center;
}

.elementor-74 .elementor-element.elementor-element-1c8de2e8>.elementor-widget-container {
    padding: 0px 0px 0px 20px;
}

.elementor-74 .elementor-element .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif !important;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-image-box-div {
    position: relative;
    height: max-content !important;
}

.elementor-74 .elementor-element.elementor-element-686f104e {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 2px;
}

.elementor-74 .elementor-element .elementor-button-book {
    font-family: "Redressed", Sans-serif;
    width: auto !important;
    font-size: 25px;
    fill: #DCB57E;
    color: #DCB57E;
    border: 2px solid #DCB57E;
    background-color: #15162c27;
    padding: 10px 30px 10px 30px;
}

.elementor-74 .elementor-element .elementor-button-book:hover {
    fill: #FFFFFF;
    color: #FFFFFF;
    border: 2px solid #DCB57E;
    background-color: #DCB57E;
}

.elementor-74 .elementor-element.elementor-element-686f104e {
    text-align: left;
}

.elementor-74 .elementor-element.elementor-element-686f104e .elementor-heading-title {
    color: #FFFFFF;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    line-height: 1em;
}

.elementor-74 .elementor-element.elementor-element-686f104e>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
}

@media(max-width:767px) {
    .elementor-74 .elementor-element.elementor-element-686f104e .elementor-heading-title {
        font-size: 30px;
        line-height: 1.5em;
    }
}

.elementor-image-box-link {
    /* position: absolute; */
    width: 100%;
    bottom: 0.5em;
}