.elementor-87 .elementor-element.elementor-element-28b1dfa7>.elementor-container {
    min-height: 500px;
}

.elementor-87 .elementor-element.elementor-element-28b1dfa7:not(.elementor-motion-effects-element-type-background),
.elementor-87 .elementor-element.elementor-element-28b1dfa7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #06070C;
    background-image: url("../../assets/images/snapedit_1714638980507.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.elementor-87 .elementor-element.elementor-element-28b1dfa7>.elementor-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(90deg, #15162C 60%, #1e1e33 30%);
    opacity: 0.8;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-87 .elementor-element.elementor-element-28b1dfa7 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
}

.elementor-87 .elementor-element.elementor-element-5f3e8c69 {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-5f3e8c69 .elementor-heading-title {
    color: #E9C79E;
    font-family: "Arizonia", Sans-serif;
    font-size: 20em;
}

.elementor-bc-flex-widget .elementor-87 .elementor-element.elementor-element-51c313b.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-87 .elementor-element.elementor-element-51c313b.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-87 .elementor-element.elementor-element-b3ffdd9 {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-87 .elementor-element.elementor-element-b3ffdd9 .elementor-divider-separator {
    width: 10%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-87 .elementor-element.elementor-element-b3ffdd9 .elementor-divider {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-87 .elementor-element.elementor-element-57b1751c .elementor-heading-title {
    color: #E9C79E;
    font-family: "Montserrat", Sans-serif;
    font-size: 75px;
    letter-spacing: -3.5px;
}

.elementor-87 .elementor-element.elementor-element-1b285b65 .elementor-heading-title {
    color: #ffffff;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    font-style: italic;
}

.elementor-87 .elementor-element.elementor-element-1b178418 {
    --spacer-size: 150px;
}

.elementor-87 .elementor-element.elementor-element-476c50ae {
    --divider-border-style: solid;
    --divider-color: rgba(255, 255, 255, 0.55);
    --divider-border-width: 1px;
}

.elementor-87 .elementor-element.elementor-element-476c50ae .elementor-divider-separator {
    width: 50%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-87 .elementor-element.elementor-element-476c50ae .elementor-divider {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-87 .elementor-element.elementor-element-38924b14>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-87 .elementor-element.elementor-element-38924b14:not(.elementor-motion-effects-element-type-background),
.elementor-87 .elementor-element.elementor-element-38924b14>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #f6f6f6;
}

.elementor-87 .elementor-element.elementor-element-38924b14 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-87 .elementor-element.elementor-element-38924b14>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-87 .elementor-element.elementor-element-33f6630f>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-33f6630f>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-87 .elementor-element.elementor-element-24773cf7 img {
    border-radius: 300px 100px 100px 100px;
}

.elementor-bc-flex-widget .elementor-87 .elementor-element.elementor-element-59042bfd.elementor-column .elementor-widget-wrap {
    align-items: center;
}

.elementor-87 .elementor-element.elementor-element-59042bfd.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
}

.elementor-87 .elementor-element.elementor-element-59042bfd:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-87 .elementor-element.elementor-element-59042bfd>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(90deg, #ffffff 50%, rgba(242, 41, 91, 0) 0%);
}

.elementor-87 .elementor-element.elementor-element-59042bfd>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-87 .elementor-element.elementor-element-59042bfd>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-87 .elementor-element.elementor-element-4eb31d22 {
    padding: 35px 35px 35px 35px;
}

.elementor-87 .elementor-element.elementor-element-4b3d514d .elementor-heading-title {
    color: #15162C;
    font-family: "Redressed", Sans-serif;
    font-size: 75px;
}

.elementor-87 .elementor-element.elementor-element-476f2a93 .elementor-heading-title {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    letter-spacing: 1.5px;
}

.elementor-87 .elementor-element.elementor-element-24b5e019 {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.elementor-87 .elementor-element.elementor-element-263441ad {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 2px;
}

.elementor-87 .elementor-element.elementor-element-263441ad .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-right: 0;
}

.elementor-87 .elementor-element.elementor-element-263441ad .elementor-divider {
    text-align: right;
}

.elementor-87 .elementor-element.elementor-element-4c48b696 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
}

.elementor-87 .elementor-element.elementor-element-132d8b07 {
    text-align: left;
}

.elementor-87 .elementor-element.elementor-element-132d8b07 .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    line-height: 1em;
}

.elementor-87 .elementor-element.elementor-element-132d8b07>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 0px 20px 0px 0px;
}

.elementor-87 .elementor-element.elementor-element-3b82802d {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-87 .elementor-element.elementor-element-3b82802d .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-87 .elementor-element.elementor-element-3b82802d .elementor-divider {
    text-align: left;
}

.elementor-87 .elementor-element.elementor-element-3b82802d>.elementor-widget-container {
    padding: 0px 0px 0px 20px;
}

.elementor-87 .elementor-element.elementor-element-558ebf0a {
    padding: 20px 20px 0px 20px;
}

.elementor-87 .elementor-element.elementor-element-745e154d>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-1fee297 .elementor-heading-title {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    line-height: 0.6em;
    letter-spacing: 1.5px;
}

.elementor-87 .elementor-element.elementor-element-1fee297>.elementor-widget-container {
    padding: 10px 20px 10px 20px;
}

.elementor-87 .elementor-element.elementor-element-67c68cb6 {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-87 .elementor-element.elementor-element-67c68cb6>.elementor-widget-container {
    margin: 0px 0px 0px 20px;
    padding: 0px 0px 0px 20px;
    border-style: solid;
    border-width: 0px 0px 0px 7px;
}

.elementor-87 .elementor-element.elementor-element-19c6a1e0 {
    padding: 20px 20px 0px 20px;
}

.elementor-87 .elementor-element.elementor-element-5438a5b4>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-2bd1f9e3 .elementor-heading-title {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    line-height: 0.6em;
    letter-spacing: 1.5px;
}

.elementor-87 .elementor-element.elementor-element-2bd1f9e3>.elementor-widget-container {
    padding: 10px 20px 10px 20px;
}

.elementor-87 .elementor-element.elementor-element-45647f80 {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
}

.elementor-87 .elementor-element.elementor-element-45647f80>.elementor-widget-container {
    margin: 0px 0px 0px 20px;
    padding: 0px 0px 0px 20px;
    border-style: solid;
    border-width: 0px 0px 0px 7px;
}

.elementor-87 .elementor-element.elementor-element-23e1f16c {
    padding: 20px 20px 0px 20px;
}

.elementor-87 .elementor-element.elementor-element-55e5be42>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-72f3e0ef .elementor-heading-title {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    line-height: 0.6em;
    letter-spacing: 1.5px;
}

.elementor-87 .elementor-element.elementor-element-72f3e0ef>.elementor-widget-container {
    padding: 10px 20px 10px 20px;
}

.elementor-87 .elementor-element.elementor-element-252aa0f9 {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.elementor-87 .elementor-element.elementor-element-252aa0f9>.elementor-widget-container {
    margin: 0px 0px 0px 20px;
    padding: 0px 0px 0px 20px;
    border-style: solid;
    border-width: 0px 0px 0px 7px;
}

.elementor-87 .elementor-element.elementor-element-42beabd8 {
    padding: 20px 20px 0px 20px;
}

.elementor-87 .elementor-element.elementor-element-5bf22e2f>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-532ca5f7 .elementor-heading-title {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    line-height: 0.6em;
    letter-spacing: 1.5px;
}

.elementor-87 .elementor-element.elementor-element-532ca5f7>.elementor-widget-container {
    padding: 10px 20px 10px 20px;
}

.elementor-87 .elementor-element.elementor-element-699663ad {
    color: #07090f;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.elementor-87 .elementor-element.elementor-element-699663ad>.elementor-widget-container {
    margin: 0px 0px 0px 20px;
    padding: 0px 0px 0px 20px;
    border-style: solid;
    border-width: 0px 0px 0px 7px;
}

.elementor-87 .elementor-element.elementor-element-2e5f4a53>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-87 .elementor-element.elementor-element-2e5f4a53:not(.elementor-motion-effects-element-type-background),
.elementor-87 .elementor-element.elementor-element-2e5f4a53>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #f6f6f6;
}

.elementor-87 .elementor-element.elementor-element-2e5f4a53 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 100px 0px;
}

.elementor-87 .elementor-element.elementor-element-2e5f4a53>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-87 .elementor-element.elementor-element-72b861cc>.elementor-element-populated {
    padding: 0px 40px 0px 0px;
}

.elementor-87 .elementor-element.elementor-element-344cf44f {
    text-align: left;
}

.elementor-87 .elementor-element.elementor-element-344cf44f .elementor-heading-title {
    color: #15162c;
    font-family: "Redressed", Sans-serif;
    font-size: 35px;
    line-height: 1em;
}

.elementor-87 .elementor-element.elementor-element-344cf44f>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 0px 20px 0px 0px;
}

.elementor-87 .elementor-element.elementor-element-76eb410a {
    --divider-border-style: solid;
    --divider-color: #dcb57e;
    --divider-border-width: 3px;
}

.elementor-87 .elementor-element.elementor-element-76eb410a .elementor-divider-separator {
    width: 15%;
    margin: 0 auto;
    margin-left: 0;
}

.elementor-87 .elementor-element.elementor-element-76eb410a .elementor-divider {
    text-align: left;
}

.elementor-87 .elementor-element.elementor-element-76eb410a>.elementor-widget-container {
    padding: 0px 0px 0px 20px;
}

.elementor-87 .elementor-element.elementor-element-4903e811 {
    --spacer-size: 25px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group {
    padding-right: calc(10px/2);
    padding-left: calc(10px/2);
    margin-bottom: 22px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-form-fields-wrapper {
    margin-left: calc(-10px/2);
    margin-right: calc(-10px/2);
    margin-bottom: -22px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group.recaptcha_v3-bottomleft,
.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group.recaptcha_v3-bottomright {
    margin-bottom: 0;
}

body.rtl .elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-labels-inline .elementor-field-group>label {
    padding-left: 0px;
}

body:not(.rtl) .elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-labels-inline .elementor-field-group>label {
    padding-right: 0px;
}

body .elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-labels-above .elementor-field-group>label {
    padding-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group>label,
.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-subgroup label {
    color: #54595f;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-type-html {
    padding-bottom: 0px;
    font-family: "Montserrat", Sans-serif;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group .elementor-field {
    color: #000000;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group .elementor-field,
.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-subgroup label {
    font-family: "Montserrat", Sans-serif;
    font-size: 13px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group:not(.elementor-field-type-upload) .elementor-field:not(.elementor-select-wrapper) {
    background-color: #ffffff;
    border-color: #dcb57e;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group .elementor-select-wrapper select {
    background-color: #ffffff;
    border-color: #dcb57e;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-field-group .elementor-select-wrapper::before {
    color: #dcb57e;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-button {
    font-family: "Redressed", Sans-serif;
    font-size: 25px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .e-form__buttons__wrapper__button-next {
    background-color: #0b0b17;
    color: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-button[type="submit"] {
    background-color: #0b0b17;
    color: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-button[type="submit"] svg * {
    fill: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .e-form__buttons__wrapper__button-previous {
    color: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .e-form__buttons__wrapper__button-next:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-button[type="submit"]:hover {
    background-color: #dcb57e;
    color: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .elementor-button[type="submit"]:hover svg * {
    fill: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 .e-form__buttons__wrapper__button-previous:hover {
    color: #ffffff;
}

.elementor-87 .elementor-element.elementor-element-131d4f79 {
    --e-form-steps-indicators-spacing: 20px;
    --e-form-steps-indicator-padding: 30px;
    --e-form-steps-indicator-inactive-secondary-color: #ffffff;
    --e-form-steps-indicator-active-secondary-color: #ffffff;
    --e-form-steps-indicator-completed-secondary-color: #ffffff;
    --e-form-steps-divider-width: 1px;
    --e-form-steps-divider-gap: 10px;
}

.elementor-87 .elementor-element.elementor-element-131d4f79>.elementor-widget-container {
    margin: 0px 20px 0px 20px;
    padding: 30px 40px 30px 40px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(219, 212, 201, 0.88);
}

.elementor-bc-flex-widget .elementor-87 .elementor-element.elementor-element-3b8b1186.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-87 .elementor-element.elementor-element-3b8b1186.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-87 .elementor-element.elementor-element-3b8b1186>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-87 .elementor-element.elementor-element-3b8b1186>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-87 .elementor-element.elementor-element-7925b200 img {
    border-radius: 100px 110px 350px 100px;
}







.elementor-87 .elementor-element.elementor-element-f61149b.elementor-position-right .elementor-image-box-img {
    margin-left: 0px;
}

.elementor-87 .elementor-element.elementor-element-f61149b.elementor-position-left .elementor-image-box-img {
    margin-right: 0px;
}

.elementor-87 .elementor-element.elementor-element-f61149b.elementor-position-top .elementor-image-box-img {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-f61149b .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-87 .elementor-element.elementor-element-f61149b .elementor-image-box-img img {
    transition-duration: 0.3s;
}

.elementor-87 .elementor-element.elementor-element-f61149b .elementor-image-box-wrapper {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-f61149b .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-87 .elementor-element.elementor-element-39260cb.elementor-position-right .elementor-image-box-img {
    margin-left: 0px;
}

.elementor-87 .elementor-element.elementor-element-39260cb.elementor-position-left .elementor-image-box-img {
    margin-right: 0px;
}

.elementor-87 .elementor-element.elementor-element-39260cb.elementor-position-top .elementor-image-box-img {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-39260cb .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-87 .elementor-element.elementor-element-39260cb .elementor-image-box-img img {
    transition-duration: 0.3s;
}

.elementor-87 .elementor-element.elementor-element-39260cb .elementor-image-box-wrapper {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-39260cb .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0.elementor-position-right .elementor-image-box-img {
    margin-left: 0px;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0.elementor-position-left .elementor-image-box-img {
    margin-right: 0px;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0.elementor-position-top .elementor-image-box-img {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0 .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0 .elementor-image-box-img img {
    transition-duration: 0.3s;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0 .elementor-image-box-wrapper {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-6cab89c0 .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-87 .elementor-element.elementor-element-3c52a606.elementor-position-right .elementor-image-box-img {
    margin-left: 0px;
}

.elementor-87 .elementor-element.elementor-element-3c52a606.elementor-position-left .elementor-image-box-img {
    margin-right: 0px;
}

.elementor-87 .elementor-element.elementor-element-3c52a606.elementor-position-top .elementor-image-box-img {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-3c52a606 .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-87 .elementor-element.elementor-element-3c52a606 .elementor-image-box-img img {
    transition-duration: 0.3s;
}

.elementor-87 .elementor-element.elementor-element-3c52a606 .elementor-image-box-wrapper {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-3c52a606 .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-87 .elementor-element.elementor-element-58b69b47.elementor-position-right .elementor-image-box-img {
    margin-left: 0px;
}

.elementor-87 .elementor-element.elementor-element-58b69b47.elementor-position-left .elementor-image-box-img {
    margin-right: 0px;
}

.elementor-87 .elementor-element.elementor-element-58b69b47.elementor-position-top .elementor-image-box-img {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-58b69b47 .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-87 .elementor-element.elementor-element-58b69b47 .elementor-image-box-img img {
    transition-duration: 0.3s;
}

.elementor-87 .elementor-element.elementor-element-58b69b47 .elementor-image-box-wrapper {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-58b69b47 .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6.elementor-position-right .elementor-image-box-img {
    margin-left: 0px;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6.elementor-position-left .elementor-image-box-img {
    margin-right: 0px;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6.elementor-position-top .elementor-image-box-img {
    margin-bottom: 0px;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6 .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6 .elementor-image-box-img img {
    transition-duration: 0.3s;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6 .elementor-image-box-wrapper {
    text-align: center;
}

.elementor-87 .elementor-element.elementor-element-171fe5f6 .elementor-image-box-title {
    margin-bottom: 10px;
    color: #15162C;
    font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

@media(max-width:1024px) {

    .elementor-87 .elementor-element.elementor-element-28b1dfa7:not(.elementor-motion-effects-element-type-background),
    .elementor-87 .elementor-element.elementor-element-28b1dfa7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-repeat: no-repeat;
        background-size: 150% auto;
    }

    .elementor-87 .elementor-element.elementor-element-28b1dfa7 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-5f3e8c69 .elementor-heading-title {
        font-size: 15em;
    }

    .elementor-87 .elementor-element.elementor-element-1b178418 {
        --spacer-size: 80px;
    }

    .elementor-87 .elementor-element.elementor-element-2e5f4a53 {
        padding: 0px 0px 0px 0px;
    }

    .elementor-87 .elementor-element.elementor-element-72b861cc>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }

    .elementor-87 .elementor-element.elementor-element-3b8b1186>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }
}

@media(max-width:767px) {

    .elementor-87 .elementor-element.elementor-element-28b1dfa7:not(.elementor-motion-effects-element-type-background),
    .elementor-87 .elementor-element.elementor-element-28b1dfa7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-repeat: no-repeat;
        background-size: 740px auto;
    }

    .elementor-87 .elementor-element.elementor-element-28b1dfa7 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-5f3e8c69 .elementor-heading-title {
        font-size: 100px;
    }

    .elementor-87 .elementor-element.elementor-element-b3ffdd9 .elementor-divider {
        text-align: center;
    }

    .elementor-87 .elementor-element.elementor-element-b3ffdd9 .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-87 .elementor-element.elementor-element-57b1751c {
        text-align: center;
    }

    .elementor-87 .elementor-element.elementor-element-1b285b65 {
        text-align: center;
    }

    .elementor-87 .elementor-element.elementor-element-1b285b65 .elementor-heading-title {
        font-size: 30px;
    }

    .elementor-87 .elementor-element.elementor-element-1b178418 {
        --spacer-size: 60px;
    }

    .elementor-87 .elementor-element.elementor-element-476c50ae .elementor-divider {
        text-align: center;
    }

    .elementor-87 .elementor-element.elementor-element-476c50ae .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-87 .elementor-element.elementor-element-4b3d514d .elementor-heading-title {
        font-size: 40px;
        line-height: 1em;
    }

    .elementor-87 .elementor-element.elementor-element-476f2a93 .elementor-heading-title {
        font-size: 20px;
        line-height: 1em;
    }

    .elementor-87 .elementor-element.elementor-element-1fee297 .elementor-heading-title {
        font-size: 20px;
        line-height: 1em;
    }

    .elementor-87 .elementor-element.elementor-element-2bd1f9e3 .elementor-heading-title {
        font-size: 20px;
        line-height: 1em;
    }

    .elementor-87 .elementor-element.elementor-element-72f3e0ef .elementor-heading-title {
        font-size: 20px;
        line-height: 1em;
    }

    .elementor-87 .elementor-element.elementor-element-532ca5f7 .elementor-heading-title {
        font-size: 20px;
        line-height: 1em;
    }

    .elementor-87 .elementor-element.elementor-element-72b861cc>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-87 .elementor-element.elementor-element-3b8b1186>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-87 .elementor-element.elementor-element-f61149b .elementor-image-box-img {
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-39260cb .elementor-image-box-img {
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-6cab89c0 .elementor-image-box-img {
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-3c52a606 .elementor-image-box-img {
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-58b69b47 .elementor-image-box-img {
        margin-bottom: 0px;
    }

    .elementor-87 .elementor-element.elementor-element-171fe5f6 .elementor-image-box-img {
        margin-bottom: 0px;
    }
}

@media(min-width:768px) {
    .elementor-87 .elementor-element.elementor-element-1abb0b65 {
        width: 40%;
    }

    .elementor-87 .elementor-element.elementor-element-51c313b {
        width: 60%;
    }

    .elementor-87 .elementor-element.elementor-element-33f6630f {
        width: 40%;
    }

    .elementor-87 .elementor-element.elementor-element-59042bfd {
        width: 60%;
    }

    .elementor-87 .elementor-element.elementor-element-72b861cc {
        width: 55%;
    }

    .elementor-87 .elementor-element.elementor-element-3b8b1186 {
        width: 45%;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-87 .elementor-element.elementor-element-33f6630f {
        width: 100%;
    }

    .elementor-87 .elementor-element.elementor-element-59042bfd {
        width: 100%;
    }

    .elementor-87 .elementor-element.elementor-element-72b861cc {
        width: 100%;
    }

    .elementor-87 .elementor-element.elementor-element-3b8b1186 {
        width: 100%;
    }
}